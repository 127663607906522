import React from 'react'
import cavslogo from '../../assets/loading/cavslogo.png'
import line from '../../assets/loading/line.png'
import virtualicons from '../../assets/loading/virtualicons.png'
import verizonlogo from '../../assets/loading/verizon.png'
import styles from '../../styles/loading.module.css'
import LoadingBar from '../../utils/LoadingBar'
import LoadingCircle from '../../utils/LoadingCircle'
// import Image from 'next/image'
import CokeCavsLogo from '../../components/Branding/CokeCavsLogo'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Footer from '../../common/Footer/Footer'


export default function LoadingPage({circle}){
    return (
        <div className={styles.loadingbackground}>
            <div className={styles.container}>
            <div className={styles.logoContainer}>
                {/* <Image src={cavslogo} alt="Logo 1" className={styles.logo} />
                <Image src={line} alt="Logo 2" className={styles.logo} />
                <Image src={verizonlogo} alt="Logo 3" className={styles.logo} /> */}
                <CokeCavsLogo />
            </div>
            <img src={virtualicons} alt="Image" className={styles.image} />
            {circle === false ? <LoadingCircle /> :
                <div className={styles.loadingBarContainer}>
                    <LoadingBar className={styles.loadingBar} />
                </div>
            }
            <p className={styles.loadingText}>
                Loading...
            </p>
        </div>
            <Footer tnc={false}/>
        </div>
    )
}