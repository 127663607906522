import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InstructionsList from '../Instructions/InstructionsList';
import LoadingCircle from '../../utils/LoadingCircle';
// import useUUIDv4 from 'react-uuid';
import SharePage from '../Share/Share';
import { OnirixEmbedSDK } from '@onirix/embed-sdk';
import LoadingPage from '../Loading/Loading';
import { trackEvent } from "../../utils/analytics";



export default function ArExperience() {
    // const uuid = useUUIDv4();
    const [open, setOpen] = React.useState(false);
    const [imageBlob, setImageBlob] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const iframeElement = `https://studio.onirix.com/projects/516ffe41ee9541168d9292dbad1fe519/webar?`
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjExNDQ3LCJwcm9qZWN0SWQiOjQ4ODM4LCJyb2xlIjozLCJpYXQiOjE2ODg3Nzc5MDl9.0dRXt_gzec8Ux7w5mtK3cbIHgmxImiZk7W63NopRvAg';
    const authDomain = 'https://virtualicons.io';

    // const embedSDK = new OnirixEmbedSDK(iframeElement, authDomain);
    // embedSDK.connect();


    useEffect(() => {
        // window.addEventListener("message", (postedMessage) => {
        // var messages = postedMessage.data;
        // for (var i = 0; i < messages.length; i++) {
        //     console.log("Received message: ", messages[i]);
        //     const postMessage = messages[i];
        //     setImageBlob(postMessage.blob);
        //         console.log("Message received", postedMessage.data);
        //         const postMessageUUID = postedMessage.data
        //         const postMessage = postedMessage.data
        //         setImageBlob(postMessage.blob);

        //         localStorage.setItem('blob', postMessage);
        // // }
        // });
        window.onmessage = function (event) {
            if (event.data.blob) {
                console.log("Blob received: ", event.data.blob);
                trackEvent("Pictures Taken", { props: { blob: event.data.blob } })
                setImageBlob(event.data.blob);
                setOpen(true);
            }
            if (event.data === 'button2') {
                console.log('button2 pressed');
                handleOpen();
            }
            if (event.data === 'button3') {
                console.log('button3 pressed');
                window.location.href = '/';
            }

            switch (event.data) {
                // case 'button1':
                //     console.log("Blob received: ", event.data.blob);
                //     setImageBlob(event.data.blob);
                //     break;
                // case 'button2':
                //     console.log('button2 pressed');
                //     handleOpen();
                //     break;
                // case 'button3':
                //     console.log('button3 pressed');
                //     window.location.href = '/';
                //     break;
                case 'Loading':
                    console.log('Loading');
                    handleOpen();
                    break;
                case 'Donovan_Mitchell':
                    console.log('Donovan_Mitchell pressed');
                    trackEvent("Player Selected", { props: { player: 'Donovan_Mitchell' } });
                    break;
                case 'Evan_Mobley':
                    console.log('Evan_Mobley pressed');
                    trackEvent("Player Selected", { props: { player: 'Evan_Mobley' } });
                    break;
                case 'Darius_Garland':
                    console.log('Darius_Garland pressed');
                    trackEvent("Player Selected", { props: { player: 'Darius_Garland' } });
                    break;
                case 'Caris_LeVert':
                    console.log('Caris_LeVert pressed');
                    trackEvent("Player Selected", { props: { player: 'Caris_LeVert' } });
                    break;
                case 'Jarrett_Allen':
                    console.log('Jarrett_Allen pressed');
                    trackEvent("Player Selected", { props: { player: 'Jarrett_Allen' } });
                    break;
                default:
                    console.log('Default Donovan_Mitchell');
                    trackEvent("Player Selected", { props: { player: 'Donovan_Mitchell_Default' } });
                    break;
            }

            console.log("Button press message received: ", event.data);
        };

        imageBlob !== null ? handleClose() : console.log('imageBlob is null');


    }, [imageBlob]);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        bgcolor: 'black',
        border: '1px solid black',
        outline: 'none',
        // borderRadius: 3,
        // boxShadow: 24,
        // p: 4,
        zIndex: 9999999,
    };

    return (
        <div>
            {imageBlob !== null ? <SharePage blob={imageBlob} /> :
                <iframe id="onirixx"
                    style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%', zIndex: 10, display: 'block', border: 'none' }}
                    src={iframeElement + 'token=' + token}
                    allow="camera;gyroscope;accelerometer;magnetometer;fullscreen;xr-spatial-tracking;geolocation;">
                </iframe>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <LoadingPage circle={false} />
                </Box>
            </Modal>
        </div>
    );
}