import logo from './logo.svg';
import './App.css';
import LoadingPage from './components/Loading/Loading';
import StartPage from './components/Start/Start';
import Instructions from './components/Instructions/Instructions';
import ArExperience from './components/AR/Ar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={< LoadingPage circle={true}/>}></Route>
          <Route exact path='/start' element={<StartPage />}></Route>
          <Route exact path='/instructions' element={<Instructions />}></Route>
          <Route exact path='/ar' element={<ArExperience />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
