import React from 'react';
import styles from '../../styles/header.module.css';
import virtualicons from '../../assets/loading/virtualicons.png';

const Header = () => {
    return (
        <header>
            <div className={styles.header}>
                <img src={virtualicons} alt="Image" className={styles.image} />
            </div>
        </header>
    );
};

export default Header;
