import React, {useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { textTransform } from '@mui/system';

const Footer = ({tnc}) => {
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  
  const handleOpenTerms = () => {
    setOpen(true);
    setTerms(true);
    setPrivacy(false);
  };
  
  const handleOpenPrivacy = () => {
    setOpen(true);
    setTerms(false);
    setPrivacy(true);
  };

  const handleClose = () => {
    setOpen(false)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    boxShadow: 24,
    p: 4,
    color: '#FBAE39',
  };

  return (
    <footer>
      {tnc === false ? (
        <div>
          Copyright 2023 Salt Experiential Commerce Inc. & WXM Tech
        </div>
      ) : (
        <div>
          <a href='https://www.rocketmortgagefieldhouse.com/terms-of-use' style={{marginRight: '44px', textDecoration:'underline'}} onClick={handleOpenTerms} >Terms of Use</a>
          <a href='https://www.rocketmortgagefieldhouse.com/privacy-policy' style={{textDecoration:'underline'}}onClick={handleOpenPrivacy}>Privacy Policy</a>
        </div>
      )}
    </footer>
  );
};

export default Footer;
