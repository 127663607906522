import React from 'react'
import Footer from '../../common/Footer/Footer'
import Header from '../../common/Header/Header'
import styles from '../../styles/rules.module.css'
import cavslogo from './cavslogo.png'
import line from './line.png'
import verizonlogo from './verizon.png'
import fullBrandingLogo from './cavs-line-verizon.png'

import { Button } from '@mui/material'

import InstructionsList from './InstructionsList'

export default function InstructionsPage() {
    return (
        <div className={styles.startbody}>
            <Header />
            <div className={styles.container}>
                <InstructionsList />
                <a href="/ar" style={{ width: "100%", textAlign: "center" }}>
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: "Quicksand",
                            fontStyle: "bold",
                            fontWeight: 700,
                            fontSize: "14px",
                            lineHeight: "13px",
                            color: "black",
                            margin: "30px",
                            textAlign: "center",
                            backgroundColor: "#FBAE39",
                            border: "none",
                            borderRadius: "25px",
                            padding: "10px",
                            width: "100%",
                            maxWidth: "200px",
                            cursor: "pointer",
                        }}
                    >
                        CONTINUE
                    </Button>
                </a>




                <div className={styles.logoContainer}>
                    {/* <img src={cavslogo} alt="Logo 1" className={styles.cavslogo} />
                    <img src={line} alt="Logo 2" className={styles.line} />
                    <img src={verizonlogo} alt="Logo 3" className={styles.cokelogo} /> */}
                    <img src={fullBrandingLogo} alt="Logo 1" className={styles.cavslogo} />
                </div>
            </div>
        </div >
    )

}