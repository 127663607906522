import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import styles from '../styles/loading.module.css';

export default function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0);
  // const router = useRouter();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
        //   router.push('/start');
        window.location.href = '/start';
          return 0;
        }
        const diff = Math.random() * 50;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', height:'14px', color: '#F0B152', margin: '6px', backgroundColor: 'transparent' }}>
      <LinearProgress variant="determinate" value={progress}  sx={{
        height: '13px', borderRadius: '25px', backgroundColor: 'transparent', backgroundColor: `transparent`,
        "& .MuiLinearProgress-bar": {
          backgroundColor: `#F0B152`
        }
      }} />
    </Box>
  );
}
