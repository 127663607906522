import React from 'react'
import cavslogo from './cavslogo.png'
import line from './line.png'
import cokelogo from './Coke.png'
import verizonlogo from './verizon.png'
import styles from '../../styles/loading.module.css'
// import Image from 'next/image'



export default function CokeCavsLogo() {
    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src={cavslogo} alt="Logo 1" className={styles.logo} />
                <img src={line} alt="Logo 2" className={styles.logo} />
                <img src={verizonlogo} alt="Logo 3" className={styles.logo} />
            </div>
        </div>
    )
}
