"use client";
import { useState, useEffect, useRef } from "react";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { initializeApp } from "firebase/app";
import LoadingCircle from "../../utils/LoadingCircle";
import styles from "../../styles/share.module.css";
import html2canvas from "html2canvas";
import VirtualIcons from "./vi-banner.png";
import CavsLogo from "./cavs-line-verizon.png";
import FullBranding from "./full-share-branding.png";
import * as htmlToImage from "html-to-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../../utils/analytics";

export default function SharePage(blob) {
  console.log("blob", blob.blob);
  const [url, setUrl] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const blobImage = blob.blob;
  const blobImageConversion = URL.createObjectURL(blobImage);

  // Your web app's Firebase configuration
  // const firebaseConfig = {
  //     apiKey: "AIzaSyD1q44979KGZnEZhr1QWQM0ceavlDw_L4w",
  //     authDomain: "nba-cavaliers-ar.firebaseapp.com",
  //     projectId: "nba-cavaliers-ar",
  //     storageBucket: "nba-cavaliers-ar.appspot.com",
  //     messagingSenderId: "76510319025",
  //     appId: "1:76510319025:web:c581d7103b64bb3709ed90"
  // };
  // // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const searchParams = useSearchParams();
  // // E.g. `/share?uuid=1234`
  // const uuid = searchParams.get('uuid');
  // const storage = getStorage();
  // const starsRef = ref(storage, `${uuid}/Cavalier_AR_Player.png`);
  // const brandedImageRef = ref(storage, `${uuid}/Cavalier_AR_Player_Branded.png`);

  // useEffect(() => {
  //     setIsLoading(true);
  //     getDownloadURL(starsRef)
  //         .then((url) => {
  //             setUrl(url);
  //             setIsLoading(false);
  //         })
  //         .catch((error) => {
  //             switch (error.code) {
  //                 case 'storage/object-not-found':
  //                     break;
  //                 case 'storage/unauthorized':
  //                     break;
  //                 case 'storage/canceled':
  //                     break;
  //                 case 'storage/unknown':
  //                     break;
  //                 default:
  //                     window.location.reload();
  //                     break;
  //             }
  //             setIsLoading(false);
  //         });
  // }, []);

  const domEl = useRef(null);

  // useEffect(() => {
  //     if (url) {
  //         setIsLoading(true);
  //         async function getImage() {
  //             const dataUrl = await htmlToImage.toPng(domEl.current);

  //             const blob = await fetch(dataUrl).then(r => r.blob());
  //             setShareUrl(blob);
  //             await uploadBytes(brandedImageRef, blob);
  //             setIsLoading(false);
  //             console.log('Screenshot has been uploaded to Firebase Storage.');
  //         }
  //         getImage();
  //     }
  // }, [url]);

  // Fuction to handle the share action
  // const handleShare = async () => {
  //     try {
  //         const dataUrl = await htmlToImage.toPng(domEl.current);
  //         const blob = await fetch(dataUrl).then(r => r.blob());

  //         // Check if the navigator.share function is available
  //         const data = {
  //             files: [
  //                 new File([blob], 'file.png', { type: 'image/png' }),
  //             ]
  //         };
  //         if (navigator.canShare && navigator.canShare(data)) {
  //             navigator.share(data);
  //         }
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };

  // Create a navigator.share function to handle the share action of an image blob to social media
  //   const handleShare = async () => {
  //     try {
  //       const dataUrl = await htmlToImage.toPng(domEl.current);
  //       const blob = await fetch(dataUrl).then((r) => r.blob());
  //       console.log("blob from share: ", blob);
  //       const data = {
  //         files: [new File([blob], "Cavaliers.png", { type: "image/png" })],
  //       };
  //       if (navigator.canShare && navigator.canShare(data)) {
  //         navigator.share(data);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const handleShare = async () => {
    try {
      const canvas = await html2canvas(domEl.current);
      const dataUrl = canvas.toDataURL();
      const blob = await fetch(dataUrl).then((r) => r.blob());
      console.log("blob from share: ", blob);
      const data = {
        files: [new File([blob], "Cavaliers.png", { type: "image/png" })],
      };
      if (navigator.canShare && navigator.canShare(data)) {
        navigator.share(data);
      }
      trackEvent("Share Page Buttons", { props: { button: "Share Button" } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    trackEvent("Share Page Buttons", {
      props: { button: "Back Arrow Button" },
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  };

  const handleEnter = () => {
    trackEvent("Share Page Buttons", {
      props: { button: "Enter Now Button" },
    });
    setTimeout(() => {
      window.location.href =
        "https://plugplayscore.vz-experiences.com/cavaliers";
    }, 200);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              top: 20,
              fontFamily: "TerminaBold",
            }}
          >
            <button
              onClick={handleBack}
              style={{
                width: "40px",
                height: "30px",
                border: "0px solid black",
                backgroundColor: "#FBAE39",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                zIndex: "100",
                borderRadius: "10px",
                // marginTop: "20px",
                top: "10px",
                left: "45px",
                position: "absolute",
                fontFamily: "Termina",
                fontSize: "25px",
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </div>
          <div id="domEl" ref={domEl} className={styles.shareContainer}>
            <div
              style={{
                // height: '1920px',
                // transform: 'scale(.35)',
                // left: '0',
                width: "90%",
                aspectRatio: "9/16",
                backgroundImage: `url(${blobImageConversion})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "absolute",
                // top: "20px",
                transformOrigin: "center",
              }}
            ></div>
            <div className={styles.brandingOverlay}>
              <img
                src={FullBranding}
                style={{
                  // height: '1920px',
                  // transform: 'scale(.35)',
                  left: "20px",
                  width: "90%",
                  aspectRatio: "9/16",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  // top: "20px",
                  transformOrigin: "center center",
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              bottom: 5,
            }}
          >
            <button
              onClick={handleEnter}
              style={{
                width: "40%",
                height: "40px",
                border: "0px solid black",
                backgroundColor: "grey",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "bold",
                borderRadius: "25px",
                marginRight: "10px",
              }}
            >
              Enter Now
            </button>
            <button
              onClick={handleShare}
              style={{
                width: "40%",
                height: "40px",
                border: "0px solid black",
                backgroundColor: "#FBAE39",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "bold",
                borderRadius: "25px",
              }}
            >
              Share Image
            </button>
          </div>
        </>
      )}
    </div>
  );
}
