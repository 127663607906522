import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import finger from "./finger.png";
import podium from "./podium.png";
import camera from "./camera.png";
import soc from "./soc.png";

export default function InstructionsList() {
  return (
    <List sx={{ width: "95%", bgcolor: "transparent", color: "white", margin: "90px" }}>
      <ListItem>
        <ListItemAvatar>
          <img
            alt="Finger Pointing"
            src={finger}
            style={{ width: "80%", height: "auto" }}
          />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontSize: ".9rem" }}
          primary="ALLOW ACCESS TO YOUR PHONE CAMERA"
          sx={{ marginLeft: "30px" }}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <img
            alt="Podium"
            src={podium}
            style={{ width: "80%", height: "auto" }}
          />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontSize: ".9rem" }}
          primary="POINT YOUR PHONE CAMERA AT THE EMPTY BASE"
          sx={{ marginLeft: "30px" }}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <img
            alt="Camera"
            src={camera}
            style={{ width: "75%", height: "auto" }}
          />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontSize: ".9rem" }}
          primary="TAKE YOUR PHOTO!"
          sx={{ marginLeft: "20px" }}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <img alt="Share" src={soc} style={{ width: "80%", height: "auto" }} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontSize: ".9rem" }}
          primary="SHARE ONLINE AND ENTER FOR YOUR CHANCE TO WIN"
          sx={{ marginLeft: "35px" }}
        />
      </ListItem>
    </List>
  );
}
