import React, { useEffect } from "react";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import styles from "../../styles/start.module.css";
import cavslogo from "./cavslogo.png";
import line from "./line.png";
import verizonlogo from "./verizon.png";
import fullBrandingLogo from "./cavs-line-verizon.png";
import players from "./players-v3.png";
import { Button } from "@mui/material";

export default function StartPage() {
  useEffect(() => {
    console.log(window.screen.width);
    console.log(window.screen.height);
  }, []);
  return (
    <div className={styles.startbody}>
      <Header />
      <div className={styles.container}>
        <img src={players} alt="Image" className={styles.image} />
        <h1 className={styles.title}>GET CLOSE TO YOUR CAVS</h1>
        <h2 className={styles.subtitle}>
        Get closer to your favorite players than ever before and enter for a chance to win a Connected Fan Cave thanks to Verizon
        </h2>
        <a href="/instructions" style={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Quicksand",
              fontStyle: "bold",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "13px",
              color: "black",
              margin: "30px",
              textAlign: "center",
              backgroundColor: "#FBAE39",
              border: "none",
              borderRadius: "25px",
              padding: "10px",
              width: "100%",
              maxWidth: "200px",
              cursor: "pointer",
            }}
          >
            Start
          </Button>
        </a>

        <div className={styles.logoContainer}>
          {/* <img src={cavslogo} alt="Logo 1" className={styles.cavslogo} />
                    <img src={line} alt="Logo 2" className={styles.line} />
                    <img src={verizonlogo} alt="Logo 3" className={styles.cokelogo} /> */}
          <img
            src={fullBrandingLogo}
            alt="Logo 1"
            className={styles.cavslogo}
          />
        </div>
      </div>
      <Footer tnc={true} />
    </div>
  );
}
